<template>
  <div class="prices-info__suggested-sale-price">
    <s-popover
      v-if="renderPopover"

      v-model="popoverActive"
      :content="pricePopoverLang"
      placemen="bottom"
      trigger="unset"
      show-close-icon
      fix-disappear-position
      :prop-style="popoverStyle"
      class="popover-container"
      @click.stop="popoverClick"
    >
      <template #reference>
        <div 
          class="suggested-popover" 
        >
          <SuggestedSalePrice
            :value="priceValue"
            :label-text="lowestPriceLabelLang"
            :price-delete-style="priceDeleteStyle"
            :flip-text="flipText"
          />
          <i 
            ref="iconCaution"
            :data-id="id"
            class="icon-suggested-caution sh_pc_sui_icon_caution_14px"
          ></i>
        </div>
      </template>
    </s-popover>
    <SuggestedSalePrice
      v-else
      :value="priceValue"
      :label-text="lowestPriceLabelLang"
      :price-delete-style="priceDeleteStyle"
      :flip-text="flipText"
    />
  </div>
</template>

<script setup name="SuggestedSalePriceContainer">
import SuggestedSalePrice from './SuggestedSalePrice.vue'
import { SPopover } from '@shein-aidc/sui-popover'

import { toRefs } from 'vue'

import { useCardPopover } from '../../hooks/useCardPopover.js'

const props = defineProps({
  goodsInfo: {
    type: Object,
    default: () => {},
  },
  priceData: {
    type: Object,
    default: () => {},
  },
  supportVueDebug: {
    type: Boolean,
    default: false,
  }
})


const { goodsInfo, priceData } = toRefs(props)
const lowestPriceLabelLang = priceData.value?.labelText

const disabledTipPopover = priceData.value?.disabledTipPopover ?? false

const renderPopover = priceData.value?.showRetailPricePopover && !disabledTipPopover
const pricePopoverLang = priceData.value?.popoverLang ?? ''
const priceDeleteStyle = priceData.value?.priceDeleteStyle ?? false
const flipText = priceData.value?.flipText ?? false

// 德国展示原价  法国挪威展示合规价
const priceValue = priceData.value?.priceData


const {
  id,
  popoverActive,
  iconCaution,
  popoverStyle,
  popoverClick
} = renderPopover ? useCardPopover({
  goodsInfo: goodsInfo.value,
}) : {}

</script>

<style lang="less" scoped>
:deep(.popover-container.sui-popover__trigger) {
  display: inline;
}

.prices-info__suggested-sale-price {
  flex: 1;
  min-width: 0;
  width: fit-content;
}
.suggested-popover {
  cursor: pointer;

  display: flex;
  align-items: center;
  line-height: 14px;
}
.icon-suggested-caution {
  flex: 0 0 14px;

  margin-left: 2px;
  width: 14px;
  height: 14px;
}
</style>
